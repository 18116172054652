import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { formatDate } from '@telerik/kendo-intl';
import { Dialog } from '@progress/kendo-react-dialogs';
import OnOutsiceClick from 'react-outclick';

import WSClient, { erNoClientCertficate, erUserHasNoActiveCertficate, erClientCertificateNotMatchRegisteredOne, erOK } from '../WSClient.js';
import constants from '../Constants.js';
import Dictionaries from 'Dictionaries.js';

import CentreSelection from './pages/CentreSelection.js';

import './Header.css';

import { ReactComponent as UserImage } from '../images/userIcon.svg';
import { ReactComponent as NewImage } from '../images/new.svg';
import { ReactComponent as EditImage } from '../images/edit.svg';
import { ReactComponent as DeleteImage } from '../images/delete.svg';
import { ReactComponent as DeleteAdminImage } from '../images/deleteAdmin.svg';

import { ReactComponent as ReplyImage } from '../images/mailReply.svg';
import { ReactComponent as ReplyAllImage } from '../images/mailReplyAll.svg';
import { ReactComponent as ForwardImage } from '../images/mailForward.svg';

import { ReactComponent as UserPlusImage } from '../images/PatientNew.svg';
import { ReactComponent as ChangeRegisterImage } from '../images/PatientReArrange.svg';
import { ReactComponent as FreePatientImage } from '../images/MoveToList.svg';
import { ReactComponent as AddFreePatientImage } from '../images/PatientFromList.svg';


class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showUserMenu: false,
            showCentreSelection: false,
            clientCertificateState: erNoClientCertficate,
            clientCertificateNotAfter: null,
            smsValidation: false,
        };
    }

    //
    componentDidMount() {

        WSClient.addUserChangeListener(this.handleUserChange);
        this.handleUserChange();
    }


    //
    componentWillUnmount() {
        WSClient.removeUserChangeListener(this.handleUserChange);
    }

    // Tato metoda je volána ve chvíli, kdy dojde ke změně stavu uživatel přihlášen x nepřihlášen, nebo dojde k nějaké chybě komunikace
    handleUserChange = () => {

        // Změna stavu přihlášení
        this.setState({
            firstName: WSClient.currentUser.data.firstName,
            surname: WSClient.currentUser.data.surname,
            centreCode: WSClient.currentCentreCode,
            centreCity: WSClient.currentCentreCode && Dictionaries.findCentre(WSClient.currentCentreCode).city,
            groups: WSClient.currentUser.data.groups.map(w => Dictionaries.findUserGroup(w).name).join(', '),
            showUserMenu: false,
            clientCertificateState: WSClient.currentUser.clientCertificateState,
            clientCertificateNotAfter: WSClient.currentUser.clientCertificateNotAfter,
            clientCertificateName: WSClient.currentUser.clientCertificateName,
            smsValidation: WSClient.currentUser.smsValidation,
        });
    }

    // Tato metoda obsluhuje najetí myší na ikonku uživatele, zobrazí konktextové menu
    handleUserMouseEnter = () => {
        this.setState(state => ({
            showUserMenu: true
        }));
    }

    // Obsluha výběru položky v kontextovém menu uživatele
    handleUserMenuSelect = (e) => {

        // Odhlášení uživatele
        switch (e.item.data.id) {
            case 0: WSClient.closeAccessToken(); break;
            case 2: this.setState({ showCentreSelection: true }); break;
            default:
                // Přímo navigované stránky
                this.props.history.push(e.item.data.route);
        }
    }

    // reakce na kliknutí na tlačítko v obrázkovém menu
    onMenuClick = (button) => {
        if (this.props.topMenuButtonHandler.length === 0)
            throw new Error("Není zaregitrována obsluha tlačítek v hlavičce");

        // Provedení akce
        this.props.topMenuButtonHandler.forEach(w => w(button));
    }

    render() {
        let clientCertificateInfo = null;
        let clientCertificateTooltip = "";

        switch (this.state.clientCertificateState) {
            case erNoClientCertficate: clientCertificateInfo = "Spojení bez certifikátu"; clientCertificateTooltip = "Pro plnohodnotné použití portálu RDP potřebujete mít k dispozici osobní certifkát."; break;
            case erUserHasNoActiveCertficate: clientCertificateInfo = "Nemáte zaregistrovaný certifikát"; clientCertificateTooltip = "Pro plnohodnotné použití portálu RDP potřebujete použitý certifikát spárovat s přihlášeným uživatelem.\nPříslušnou funkci najdete v menu pod ikonkou uživatele."; break;
            case erClientCertificateNotMatchRegisteredOne: clientCertificateInfo = "Použit jiný než registrovaný certifikát"; clientCertificateTooltip = "Přihlášený uživatel má zaregistrovaný jiný certifikát, než který byl použit pro vytvoření tohoto spojení.\nChcete-li začít používat tento certifikát spárujte ho s přihlášeným uživatelem.\nPříslušnou funkci najdete v menu pod ikonkou uživatele."; break;
            case erOK: clientCertificateInfo = this.state.smsValidation ? "Autorizováno pomoci SMS" : `Certifikát platný do ${formatDate(new Date(this.state.clientCertificateNotAfter), "d", this.props.locale)}`; break;
            default: throw new Error("UknownResultCode");
        }

        const centre = Dictionaries.centres.find(w => w.code === this.state.centreCode);
        
        return (
            <React.Fragment>
                <div className="Content">
                    <div className="HeaderTitles">
                        <div className="Title">Registr dialyzovaných pacientů
                            {window.location.port === "8443" && <span className="test">(TESTOVACÍ PROSTŘEDÍ)</span>}
                            {window.location.port === "5003" && <span className="test">(LOCALHOST)</span>}
                        </div>
                        <div>
                            <span className="BreadCrumbNavigationTitle">{this.props.breadCrumbNavigationTitle}</span>
                            <span className="BreadCrumbNavigationText">{this.props.breadCrumbNavigationText}</span>
                        </div>
                    </div>
                    <div className="HeaderInfoText">{this.props.headerInfoText}</div>

                    <div className="UserName">
                        <div className="TopMenu">
                            {this.props.topMenuButtonDisabled.mail &&
                                <span className="MailMenu">
                                    <button disabled={this.props.topMenuButtonDisabled.mail[constants.tmbReply - constants.tmbReply] ? "disabled" : ""}
                                        title="Odpovědět"
                                        onClick={() => this.onMenuClick(constants.tmbReply)}>
                                        <ReplyImage /></button>
                                    <button disabled={this.props.topMenuButtonDisabled.mail[constants.tmbReplyAll - constants.tmbReply] ? "disabled" : ""}
                                        title="Odpovědět všem"
                                        onClick={() => this.onMenuClick(constants.tmbReplyAll)}>
                                        <ReplyAllImage /></button>
                                    <button disabled={this.props.topMenuButtonDisabled.mail[constants.tmbForward - constants.tmbReply] ? "disabled" : ""}
                                        title="Přeposlat"
                                        onClick={() => this.onMenuClick(constants.tmbForward)}>
                                        <ForwardImage /></button>
                                </span>}

                            {this.props.topMenuButtonDisabled.data &&
                                <span className="MailMenu">
                                    <button disabled={this.props.topMenuButtonDisabled.data[constants.tmbAddPatient - constants.tmbAddPatient] ? "disabled" : ""}
                                        title="Nový pacient"
                                        onClick={() => this.onMenuClick(constants.tmbAddPatient)}>
                                        <UserPlusImage /></button>
                                    <button disabled={this.props.topMenuButtonDisabled.data[constants.tmbChangeRegister - constants.tmbAddPatient] ? "disabled" : ""}
                                        title="Změnit status pacienta"
                                        onClick={() => { if (window.confirm('Opravdu chcete přesunout pacienta do druhého registru?')) this.onMenuClick(constants.tmbChangeRegister); }}>
                                        <ChangeRegisterImage /></button>
                                    <button disabled={this.props.topMenuButtonDisabled.data[constants.tmbFreePatient - constants.tmbAddPatient] ? "disabled" : ""}
                                        title="Uvolnění pacienta ze střediska (pacient přešel do jiného střediska)"
                                        onClick={() => { if (window.confirm('Přejete si opravdu uvolnit pacienta ze svého střediska?')) this.onMenuClick(constants.tmbFreePatient); }}>
                                        <FreePatientImage /></button>
                                    <button disabled={this.props.topMenuButtonDisabled.data[constants.tmbAddFreePatient - constants.tmbAddPatient] ? "disabled" : ""}
                                        title="Přidat pacienta ze seznamu volných pacientů"
                                        onClick={() => this.onMenuClick(constants.tmbAddFreePatient)}>
                                        <AddFreePatientImage /></button>
                                    {this.props.topMenuButtonDisabled.data[constants.tmbDeletePatient - constants.tmbAddPatient] !== null &&
                                        <button disabled={this.props.topMenuButtonDisabled.data[constants.tmbDeletePatient - constants.tmbAddPatient] ? "disabled" : ""}
                                            title="Kompletní vymazání pacienta"
                                            onClick={() => { if (window.confirm('Opravdu chcete provést kompletní vymazání pacienta?')) this.onMenuClick(constants.tmbDeletePatient); }}
                                        >
                                            <DeleteAdminImage />
                                        </button>}
                                </span>}

                            <button disabled={this.props.topMenuButtonDisabled.edit[constants.tmbNew] ? "disabled" : ""}
                                title="Nový"
                                onClick={() => this.onMenuClick(constants.tmbNew)}
                            >
                                <NewImage />
                            </button>
                            <button disabled={this.props.topMenuButtonDisabled.edit[constants.tmbEdit] ? "disabled" : ""}
                                title="Editace"
                                onClick={() => this.onMenuClick(constants.tmbEdit)}
                            >
                                <EditImage />
                            </button>
                            <button disabled={this.props.topMenuButtonDisabled.edit[constants.tmbDelete] ? "disabled" : ""}
                                title="Vymazání"
                                onClick={() => { if (window.confirm('Opravdu chcete provést mazání?')) this.onMenuClick(constants.tmbDelete); }}
                            >
                                <DeleteImage />
                            </button>                                                                                                                       
                        </div>
                        <div>
                            <span className="Name">{this.state.firstName} {this.state.surname}</span>
                            <br />
                            <span className="Hds" title={this.state.centreCode ? `${centre.city} ${centre.headDoctor}` : ''}>{this.state.centreCode}</span>
                            <br />
                            <span className="Info" title={clientCertificateTooltip}>{clientCertificateInfo}</span>
                        </div>
                        <div className="DownButton" onMouseEnter={this.handleUserMouseEnter} ref={(div) => { this.userMenuAnchor = div; }}>
                            <UserImage />
                            <span className="k-icon k-i-collapse"></span>
                            <OnOutsiceClick
                                onOutsideClick={() => this.setState({ showUserMenu: false })} >
                                <Popup show={this.state.showUserMenu} anchor={this.userMenuAnchor} popupClass='userMenu'>
                                    <React.Fragment>
                                        <table className="userMenuInfo">
                                            <tbody>
                                                <tr>
                                                    <td className="name">{this.state.firstName} {this.state.surname}</td>
                                                    <td>{this.state.groups}</td>
                                                </tr>
                                                <tr>
                                                    <td>Středisko</td>
                                                    <td>{this.state.centreCode}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>{this.state.centreCity}</td>
                                                </tr>
                                                {!this.state.smsValidation &&
                                                    <React.Fragment>
                                                        <tr>
                                                            <td>Certifikát</td>
                                                            <td>{this.state.clientCertificateName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Platnost</td>
                                                            <td>{this.state.clientCertificateNotAfter ? formatDate(new Date(this.state.clientCertificateNotAfter), "d", this.props.locale) : ""}</td>
                                                        </tr>
                                                    </React.Fragment>}
                                            </tbody>
                                        </table>
                                        <Menu vertical={true} style={{ display: 'inline-block' }} onSelect={this.handleUserMenuSelect}>
                                            {this.state.clientCertificateState === erOK &&
                                                <MenuItem text="Nastavení uživatele" data={{ route: '/userSetting', id: 1 }} />
                                            }
                                            {WSClient.currentUser.data && WSClient.currentUser.data.centres.length > 0 && <MenuItem text="Změna střediska" data={{ id: 2 }} />}
                                            {(this.state.clientCertificateState === erUserHasNoActiveCertficate ||
                                                this.state.clientCertificateState === erClientCertificateNotMatchRegisteredOne) &&
                                                <MenuItem text="Registrace klientského certifikátu" data={{ route: '/certificateRegistration', id: 3 }} />}
                                            {this.state.clientCertificateState === erNoClientCertficate &&
                                                <MenuItem text="Ověření pomocí SMS" data={{ route: '/smsValidation', id: 1 }} />
                                            }
                                            <MenuItem text="Odhlásit se" data={{ id: 0 }} />
                                        </Menu>
                                    </React.Fragment>
                                </Popup>
                            </OnOutsiceClick>
                        </div>
                    </div>
                </div>

                {this.state.showCentreSelection && <Dialog title={"Změna střediska"} className="centreSelection">
                    <CentreSelection onSeletionDone={() => this.setState({ showCentreSelection: false })} />
                </Dialog>}
            </React.Fragment >
        );
    }
}

export default withRouter(Header);
