import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import WSClient from '../WSClient.js';
import constants from '../Constants.js';

import './Login.css';
import './LoginSecondFactor.css';

class LoginSecondFactorQuestion extends Component {

    constructor(props) {
        super(props);

        // Stav
        this.state = {
        };
    }

    //
    onYes = () => {
        this.props.setSecondFactor(constants.sfRegister);
    }

    //
    onNo = () => {
        this.props.setSecondFactor(constants.sfSMS);
    }

    // 
    render() {
        return (this.props.secondFactor === constants.sfNotActive || this.props.secondFactor === constants.sfNotMatch) ?
            <Formik
                initialValues={{
                }}
                validationSchema={Yup.object().shape({
                })}
                onSubmit={(values, { setSubmitting }) => {
                }}
            >
                {props =>
                (
                    <div className="loginTable loginTableSecondFactor">
                        <div>
                            <div>
                                <div className='loginHeader'>
                                    <div>
                                        <div className="title">Přihlásit se do RDP</div>
                                        <div className="subTitle">Vstup pro registrované DS ČR</div>
                                    </div>
                                </div>
                                <div className='loginBody'>
                                    <Form className="loginForm k-form-inline">
                                        {this.props.secondFactor === constants.sfNotActive &&
                                            <React.Fragment>
                                            <div>Přejete si certifikát <span className="bold">{WSClient.currentUser.clientCertificateName}</span> zaregistrovat?</div>                                            
                                            </React.Fragment>}
                                        {this.props.secondFactor === constants.sfNotMatch &&
                                            <React.Fragment>
                                            <div>Přejete si nahradit registrovaný certifikát <span className="bold">{WSClient.currentUser.registeredCertificateName}</span> aktuálním vybraným certifikátem <span className="bold">{WSClient.currentUser.clientCertificateName}</span>?</div>
                                            </React.Fragment>}
                                        <button type="button" className="k-button k-primary yes" onClick={this.onYes}>Ano</button>
                                        <button type="button" className="k-button k-primary send" onClick={this.onNo}>Ne</button>
                                        {props.touched.code && props.errors.code && <p className="valError">{props.errors.code}</p>}
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </Formik>
            : "";
    }
}

export default withRouter(LoginSecondFactorQuestion);
