import React, { Component, Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { Dialog } from '@progress/kendo-react-dialogs';

import WSClient, { erNoClientCertficate } from '../WSClient.js';

import Header from './Header.js';
import MainMenu from './MainMenu';

import Loading from 'images/Loading.gif';

import './LoggedUserForms.css';
import constants from '../Constants.js';

const UserHomePage = React.lazy(() => import('./pages/UserHomePage.js'));
const UserSettingPage = React.lazy(() => import('./pages/UserSetting.js'));
const CertificateRegistrationPage = React.lazy(() => import('./pages/CertificateRegistration.js'));
const SmsValidationPage = React.lazy(() => import('./pages/SmsValidation.js'));

const UserGroupListPage = React.lazy(() => import('./pages/admin/UserGroupList'));
const UserListPage = React.lazy(() => import('./pages/admin/UserList'));
const CentreListPage = React.lazy(() => import('./pages/admin/CentreList'));
const Utils = React.lazy(() => import('./pages/admin/Utils'));
const ReportsExport = React.lazy(() => import('./pages/admin/ReportsExport'));
const ReportsDirectDataExport = React.lazy(() => import('./pages/admin/ReportsDirectDataExport'));
const Log = React.lazy(() => import('./pages/admin/Log'));
const Statistics = React.lazy(() => import('./pages/admin/Statistics'));

const TreatQualityParam = React.lazy(() => import('./pages/statistic/TreatQualityParam'));
const ActualNationalDataReport = React.lazy(() => import('./pages/statistic/ActualNationalDataReport'));
const CentreReport = React.lazy(() => import('./pages/statistic/CentreReport'));

const MailPage = React.lazy(() => import('./pages/mail/Mail.js'));

const Patients = React.lazy(() => import('./pages/data/Patients.js'));
const CentreSummary = React.lazy(() => import('./pages/data/CentreSummary.js'));
const Import = React.lazy(() => import('./pages/data/Import.js'));
const Centre = React.lazy(() => import('./pages/data/Centre.js'));
const Summary2021 = React.lazy(() => import('./pages/data/summary2021/Summary2021.js'));
const DeathCause2022 = React.lazy(() => import('./pages/data/deathCause2022/DeathCause2022.js'));
const Summary = React.lazy(() => import('./pages/data/summary/Summary.js'));

const MBMessageList = React.lazy(() => import('./pages/messageBoard/MBMessageList.js'));

const Documents = React.lazy(() => import('./pages/Documents.js'));

const InsCompPatients = React.lazy(() => import('./pages/insComp/Patients.js'));

// Kontejner pro všechny komponenty, které se zobrazují pouze v případě, kdy je přihlášený uživatel
class LoggedUserForms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userLogged: WSClient.currentUser.accessTokenGuid !== '',
            width: 0,
            height: 0,
            headerInfoText: "",
            communicationError: "",
            breadCrumbNavigationTitle: "",
            breadCrumbNavigationText: "",
            topMenuButtonDisabled: {
                edit: [true, true, true],
                mail: null,   // null znamená, že celá příslušná skupina tlačítek nemá být vůbec vidět
                data: null
            },
            topMenuButtonHandler: []
        };
    }

    //
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        WSClient.addUserChangeListener(this.handleUserChange);
        WSClient.addlastCommunicationResultListeners(this.handleCommChange);
    }

    //
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        WSClient.removeUserChangeListener(this.handleStateChange);
        WSClient.removeLastCommunicationResultListeners(this.handleCommChange);
    }

    //
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth - 20, height: window.innerHeight - 170 });
    }

    // Tato metoda je volána ve chvíli změny stavu výsledku poslední komunikace se serverem
    handleCommChange = () => {

        // Vypsání chyby (erNoClientCertficate - Tato chyba se nevypisuje, zobrazuje se v hlavičce v informaci o použitém certifikátu)
        const info = WSClient.lastCommunicationResult.succeed || WSClient.lastCommunicationResult.result >= erNoClientCertficate ? "" : WSClient.lastCommunicationResult.errorMessage;
        this.setState({ communicationError: info });
    }

    // Tato metoda je volána ve chvíli, kdy dojde ke změně stavu uživatel přihlášen x nepřihlášen
    handleUserChange = () => {

        // Změna stavu přihlášení
        this.setState({
            userLogged: WSClient.currentUser.accessTokenGuid !== '',
        });

        // Pokud se přihlásil někdo z pojišťovny, pošleme ho rovnou na jeho stránku
        if (WSClient.isCurrentUserInsuranceCompany)
            this.props.history.push('/insComp/patients');
    }

    // Tato metoda je volána pro změnu drobečkové navigace
    handleBreadCrumbNavigationChange = (title, text) => {
        this.setState({ breadCrumbNavigationTitle: title, breadCrumbNavigationText: text });
    }

    // Tato metoda je volána pro změnu textu v hlavičce webu
    handleHeaderInfoTextChange = (text) => {
        this.setState({ headerInfoText: text });
    }

    // Tato metoda je volána pro změnu stavu tlačítek menu v hlavičce (new, edit, delete)
    handleTopMenuButtonDisabledChange = (newState) => {
        this.setState({ topMenuButtonDisabled: newState });
    }

    // Tato metoda je volána pro zaregistrování obsluhy tlačítek menu v hlavičce (new, edit, delete)
    addTopMenuButtonHandler = (handler) => {
        this.setState((state) => {
            let t = this.state.topMenuButtonHandler;
            t.push(handler);
            return { topMenuButtonHandler: t };
        });
    }

    // Tato metoda je volána pro odregistrování obsluhy tlačítek menu v hlavičce (new, edit, delete)
    removeTopMenuButtonHandler = () => {
        let t = this.state.topMenuButtonHandler;
        t.shift();
        this.setState({ topMenuButtonHandler: t });
    }

    // Zobrazuje se pouze v případě, když je někdo přihlášen
    render() {
        return (this.state.userLogged && this.props.secondFactor === constants.sfNone &&
            <React.Fragment>
                <div id="MainMenu">
                    <MainMenu />
                </div>
                <div id="Headline">
                    <Header
                        locale={this.props.locale}
                        headerInfoText={this.state.headerInfoText}
                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                        topMenuButtonDisabled={this.state.topMenuButtonDisabled}
                        topMenuButtonHandler={this.state.topMenuButtonHandler}
                    />
                </div>
                <div id="Middle">
                    <div className="Content">
                        {this.state.communicationError &&
                            <div id="errorBox" className="cbox">
                                <div>Došlo k chybě</div>
                                <div>{this.state.communicationError}</div>
                            </div>
                        }

                        <Suspense fallback={<Dialog className="loadingDlg">
                            <img src={Loading} alt="Probíhá načítání kódu aplikace ze serveru" />
                        </Dialog>}>
                            <Switch>
                                <Route path="/userSetting">
                                    <UserSettingPage
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText} />
                                </Route>
                                <Route path="/certificateRegistration">
                                    <CertificateRegistrationPage
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                    />
                                </Route>
                                <Route path="/smsValidation">
                                    <SmsValidationPage
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                    />
                                </Route>
                                <Route path="/data/patients/:centre?/:year?/:quarter?">
                                    <Patients
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                        locale={this.props.locale} />
                                </Route>
                                <Route path="/data/summary2021">
                                    <Summary2021
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                        locale={this.props.locale} />
                                </Route>
                                <Route path="/data/summary">
                                    <Summary
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                        locale={this.props.locale} />
                                </Route>
                                <Route path="/data/deathCause2022">
                                    <DeathCause2022
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                        locale={this.props.locale} />
                                </Route>
                                <Route path="/data/centreData">
                                    <Centre
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                        locale={this.props.locale} />
                                </Route>
                                <Route path="/data/centreSummary">
                                    <CentreSummary
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                        locale={this.props.locale} />
                                </Route>
                                <Route path="/data/import">
                                    <Import
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                    />
                                </Route>
                                <Route path="/mail">
                                    <MailPage
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                        locale={this.props.locale} />
                                </Route>
                                <Route path="/stat/treatQualityParam">
                                    <TreatQualityParam
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        locale={this.props.locale}
                                    />
                                </Route>
                                <Route path="/stat/actualNationalDataReport">
                                    <ActualNationalDataReport
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        locale={this.props.locale}
                                    />
                                </Route>
                                <Route path="/stat/centreReport">
                                    <CentreReport
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        locale={this.props.locale}
                                    />
                                </Route>
                                <Route path="/admin/users">
                                    <UserListPage
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler} />
                                </Route>
                                <Route path="/admin/userGroups">
                                    <UserGroupListPage
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler} />
                                </Route>
                                <Route path="/admin/centres">
                                    <CentreListPage
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                    />
                                </Route>
                                <Route path="/admin/utils">
                                    <Utils
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                    />
                                </Route>
                                <Route path="/admin/reportsExport">
                                    <ReportsExport
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                    />
                                </Route>
                                <Route path="/admin/reportsDirectDataExport">
                                    <ReportsDirectDataExport
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                    />
                                </Route>
                                <Route path="/admin/log">
                                    <Log
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        locale={this.props.locale}
                                    />
                                </Route>
                                <Route path="/admin/stat">
                                    <Statistics
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        locale={this.props.locale}
                                    />
                                </Route>
                                <Route path="/insComp/patients">
                                    <InsCompPatients
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                        locale={this.props.locale}
                                    />
                                </Route>
                                <Route path="/documents">
                                    <Documents
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                    />
                                </Route>
                                <Route path="/messageBoard">
                                    <MBMessageList
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        addTopMenuButtonHandler={this.addTopMenuButtonHandler}
                                        removeTopMenuButtonHandler={this.removeTopMenuButtonHandler}
                                        locale={this.props.locale}
                                    />
                                </Route>
                                <Route path="/">
                                    <UserHomePage
                                        onBreadCrumbNavigationChange={this.handleBreadCrumbNavigationChange}
                                        onTopMenuButtonDisabledChange={this.handleTopMenuButtonDisabledChange}
                                        breadCrumbNavigationTitle={this.state.breadCrumbNavigationTitle}
                                        breadCrumbNavigationText={this.state.breadCrumbNavigationText}
                                        locale={this.props.locale}
                                    />
                                </Route>
                            </Switch>
                        </Suspense>
                    </div>
                </div>
            </React.Fragment >
        );

    }
}

export default withRouter(LoggedUserForms);
