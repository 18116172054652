import * as Yup from 'yup';

import RDPUtils from './RDPUtils.js';
import WSClient from 'WSClient.js';
import Constants from 'Constants.js';
//import DateTimeRtns from 'utils/DateTimeRtns.js';

// Inicializace Yupu
export function initYup() {

    // Přidání fukce pro kontrolu rodných čísel
    Yup.addMethod(Yup.mixed, 'rcValid', function (message) {
        return this.test('rcValid', message, function (value) {
            return rcValidator(value);
        });
    });

    // Přidání fukce pro kontrolu neexistence rodného čísla v DB
    Yup.addMethod(Yup.mixed, 'pinUnique', function (message, patientID, pinDict) {
        return this.test('pinUnique', message, function (value) {
            return pinUnique(patientID, value, pinDict);
        });
    });


    // Přidání fukce pro kontrolu korektnosti sekvence průběhu léčby
    Yup.addMethod(Yup.mixed, 'courseEventOrder', function (courses, selectRow) {
        return this.test('courseEventOrder', () => lastCourseErr, function (value) {
            return courseEventOrder(courses, selectRow);
        });
    });

    // Přidání fukce pro kontrolu existence události Vstupu do RRT v průběhu léčby
    Yup.addMethod(Yup.mixed, 'courseContainsRRTStart', function (courses, year, quarter) {
        return this.test('courseContainsRRTStart', () => lastCourseErr, function (value) {
            return courseContainsRRTStart(courses, year, quarter);
        });
    });
    // Localizace textů
    /*eslint no-template-curly-in-string: "off"*/
    Yup.setLocale({
        number: {
            min: ({ min }) => `Hodnota musí být větší nebo rovna ${RDPUtils.formatNumber(min, 'cs')}.`,
            max: ({ max }) => `Hodnota musí být menší nebo rovna ${RDPUtils.formatNumber(max, 'cs')}.`,
        },
    });
}

var lastCourseErr;

// Ověření korektnosti sekvence průběhu léčby
function courseEventOrder(courses, selectRow) {

    if (courses === undefined || courses === null)
        return true;

    return new Promise((resolve, reject) => {
        WSClient.checkCourseEventOrder(courses, (result, data) => {
            lastCourseErr = data.errorMsg;
            selectRow(data.errorItemID);
            resolve(data.result);
        });
    });
}

// Ověření existence události Vstupu do RRT v průběhu léčby
function courseContainsRRTStart(courses, year, quarter) {

    if (courses === undefined || courses === null)
        return false;

    var event = courses.find(w => [Constants.dbmStartRrtTpl, Constants.dbmStartRrtHD, Constants.dbmStartRrtPD].includes(w.event));

    if (event) {
        //const nextQuarter = DateTimeRtns.GetNextQuarter(quarter, year);
        //const date = DateTimeRtns.GetFirstDayOfQuarter(nextQuarter.quarter, nextQuarter.year);
        //const dayDiff = (date - event.date) / (1000 * 3600 * 24);

        // Zatím se nebude kontrolovat
        /*if (dayDiff <= 91) {
            lastCourseErr = 'Dle data vstupu do RRT se nejedná o chronického pacienta (vstup do RRT min. 90 dní před koncem kvartálu)';
            return false;
        } else*/
            return true;
    } else {
        lastCourseErr = 'Není zadán datum vstupu do RRT';
        return false;
    }

}

// Ověření možnosti provedení editace rodného čísla pacienta, nesmí se už v DB vyskytovat
function pinUnique(patientID, pin, pinDict) {

    if (pin === undefined || pin === null)
        return true;

    if (pinDict[pin] !== undefined)
        return pinDict[pin];

    return new Promise((resolve, reject) => {
        if (pinDict[pin] !== undefined)
            resolve(pinDict[pin]);
        else
            WSClient.checkPINEdit(patientID, pin, (result, data) => { pinDict[pin] = !data.result; resolve(!data.result); });
    });
}


// Validace rodného čísla
function rcValidator(value) {

    if (value === undefined || value === null)
        return true;

    value = value.replace(/\s/g, '').replace('/', '');

    if (value.length !== 10 && value.length !== 9) {
        return false;
    }
    for (let i = 0; i < value.length; i++) {
        if (value.charCodeAt(i) < 48 || value.charCodeAt(i) > 57) {
            return false;
        }
    }
    var year = (value.charCodeAt(0) - 48) * 10 + value.charCodeAt(1) - 48;
    var month = (value.charCodeAt(2) - 48) * 10 + value.charCodeAt(3) - 48;
    var day = (value.charCodeAt(4) - 48) * 10 + value.charCodeAt(5) - 48;

    if (month > 70 && value.length === 10) month -= 70;
    else if (month > 50) month -= 50;
    else if (month > 20 && value.length === 10) month -= 20;

    if ((year >= 54 && value.length === 9) ||
        (month < 1 || month > 12 || day < 1 || day > 31)) {
        return false;
    }

    var a = 0;
    for (let i = 0; i < 9; i++) {
        a = a * 10 + (value.charCodeAt(i) - 48);
    }
    var m = 0;
    if (a % 11 !== 10) m = a % 11;
    return value.length === 9 || ((value.charCodeAt(9) - 48) === m);
}

// 
export function isNull(value, substituteValue) {
    return value === undefined || value === null ? substituteValue === undefined ? '' : substituteValue : value;
}

// 
export function emptyToNull(value, originalValue) {
    return value === '' && originalValue !== '' ? null : value;
}

//
export function toFloat(value) {
    return value === undefined || value === null || value === '' ? null : parseFloat(value);
}

//
export function toInt(value) {
    return value === undefined || value === null || value === '' ? null : parseInt(value);
}

//
export function isNumber(x) {
    return typeof x === 'number' && !isNaN(x);
}


// Příprava dat pro načtení do editačního komba
export function prepareForDropDown(emptyText, data, fncText, fncValue) {
    return [{ text: emptyText, value: '' }].concat(data.reduce((result, w) => result.concat({ text: fncText === undefined ? w : fncText(w), value: fncValue === undefined ? w : fncValue(w) }), []));
}

// Vytvoří copii objektu (deep copy)
export function deepCopy(o) {
    return JSON.parse(JSON.stringify(o));
}

// Normalizace barev hlavičky excelu generovaného telerikem
export function normalizeHeader(row) {
    row.cells.forEach(w => {
        w.background = '#FFFFFF';
        w.bold = true;
        w.color = '#000000';
        w.borderRight = { size: 1, color: '#D4D4D4' };
        w.borderTop = { size: 1, color: '#D4D4D4' };
    });
}

