import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { NumericTextBox } from '@progress/kendo-react-inputs';

import WSClient, { erOK, erWrongSMSCode } from '../WSClient.js';
import constants from '../Constants.js';

import './Login.css';
import './LoginSecondFactor.css';

class LoginSecondFactorSMS extends Component {

    constructor(props) {
        super(props);

        this.edit = null;

        // Stav
        this.state = {
            smsSent: false,
        };
    }

    // Přihlášení pomoci SMS
    smsValidation(code, callback) {
        WSClient.smsValidation(code, callback);
    }

    // Odeslání SMS s kódem
    sendSMS = () => {
        WSClient.sendSMSCode(() => { this.setState({ smsSent: true }, () => this.edit.focus()) });
    }

    //
    onLogin = () => {
        this.smsValidation(this.edit.value.toString(), (result) => {
            if (result !== erOK) {
                if (result === erWrongSMSCode)
                    alert("Zadaný kód je chybný.");
            } else {

                // Uživatel má certifikát
                const user = WSClient.currentUser;
                user.clientCertificateState = erOK;
                user.smsValidation = true;
                WSClient.currentUser = user;

                // Přesměrování na hlavní stránku
                this.props.setSecondFactor(constants.sfNone)
            }
        });
    }

    //
    onCancel = () => {
        WSClient.closeAccessToken();
        this.props.setSecondFactor(constants.sfNone);
    }

    //
    onCodeChanged = (code) => {

        if (code.toString().length === 6) 
            this.onLogin();                
    }

    //
    render() {
        return this.props.secondFactor === constants.sfSMS ?
            <Formik
                initialValues={{
                    code: null,
                }}
                validationSchema={Yup.object().shape({
                })}
                onSubmit={(values, { setSubmitting }) => {
                }}
            >
                {props =>
                (
                    <div className="loginTable loginTableSecondFactor">
                        <div>
                            <div>
                                <div className='loginHeader'>
                                    <div>
                                        <div className="title">Přihlásit se do RDP</div>
                                        <div className="subTitle">Vstup pro registrované DS ČR</div>
                                    </div>
                                </div>
                                <div className='loginBody'>
                                    <Form className="loginForm k-form-inline">                                        
                                        <div>Ověření vaší identity dokončete opsáním kódu doručeného na váš telefon.</div>
                                        <button type="button" className="k-button k-primary send" disabled={props.isSubmitting} onClick={this.sendSMS}>Odeslat SMS s ověřovacím kódem</button>
                                        <label className="k-form-field">
                                            <Field className={props.touched.code && props.errors.code ? "k-textbox valInputError" : "k-textbox"}                                                
                                                name="code"
                                            >
                                                {({ field, form, meta }) =>
                                                    <NumericTextBox
                                                        ref={edit => this.edit = edit}
                                                        disabled={!this.state.smsSent}
                                                        placeholder="SMS kód"
                                                        spinners={false}
                                                        format='######'
                                                        value={props.values["code"]}
                                                        onChange={(e) => { props.setFieldValue("code", e.target.value, true); this.onCodeChanged(e.target.value); }}
                                                        onBlur={(e) => props.setFieldTouched("code")}
                                                    />
                                                }
                                            </Field>
                                            <button type="button" className="k-button k-primary" onClick={this.onCancel}>Storno</button>
                                        </label>
                                        {props.touched.code && props.errors.code && <p className="valError">{props.errors.code}</p>}
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </Formik>
            : "";
    }
}

export default withRouter(LoginSecondFactorSMS);
