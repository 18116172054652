import { formatNumber } from '@telerik/kendo-intl';
import { addDays } from '@progress/kendo-date-math'

// Statické funkce pro manipulaci s datumy a časem.
export default class DateTimeRtns {

	// Vrací kvartál zadaného datumu
	static GetQuarter(date) {				
		return Math.floor(date.getMonth() / 3) + 1;
	}

	// Vrací datum prvního dne kvartálu
	static GetFirstDayOfQuarter(quarter, year) {
		return new Date(year, (quarter - 1) * 3, 1);
	}

	// Vrací datum posledního dne kvartálu
	static GetLastDayOfQuarter(quarter, year) {
		const q = DateTimeRtns.GetNextQuarter(quarter, year);
		const fdofnq = DateTimeRtns.GetFirstDayOfQuarter(q.quarter, q.year);
		return addDays(fdofnq, -1);
	}

	// Vypočte označení následujícího kvartálu
	static GetNextQuarter(quarter, year) {
		quarter++;

		if (quarter > 4) {
			quarter = 1;
			year++;
		}

		return {quarter, year};
	}
	
    // Vypočte označení předcházejícího kvartálu
	static GetPrevQuarter(quarter, year) {
		quarter--;

		if (quarter === 0) {
			quarter = 4;
			year--;
		}

		return { quarter, year };
	}

	//
	static toLocalDateTime(date) {
		const year = formatNumber(date.getFullYear(), "0000");
		const month = formatNumber(date.getMonth() + 1, "00");
		const day = formatNumber(date.getDate(), "00");
		const hours = formatNumber(date.getHours(), "00");
		const minutes = formatNumber(date.getMinutes(), "00");
		const seconds = formatNumber(date.getSeconds(), "00");
		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
		
	}
}
