import React, { Component } from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';

import Dictionaries from 'Dictionaries.js';

import './CentreDropDownList.css';


export default class CentreDropDownList extends Component {

    constructor(props) {
        super(props);

        // Stav
        this.state = {
            data: [],        
        };
    }
    
    componentDidMount() {
        this.setState({
            data: this.getAllData(),
        });
    }

    //
    componentDidUpdate(prevProps, prevState) {

        if (this.props.data !== prevProps.data) {
            this.setState({
                data: this.getAllData(),
            });
        }
    }

    itemRender = (li, itemProps) => {
        const dataItem = itemProps.dataItem;        
        const itemChildren = (
            <span className='centreDropDownListItem'>
                <span className='code'>{dataItem.code}</span>
                <span className='city'>{dataItem.city}</span>
                <span className='doctor'>{dataItem.headDoctor}</span>
            </span>);

        return React.cloneElement(li, li.props, itemChildren);        
    }

    valueRender = (element, value) => {
        if (!value) {
            return element;
        }
        
        const itemChildren = (
            <span className='centreDropDownListValue'>
                <span className='code'>{value.code}</span>
                <span className='city'>{value.city}</span>
                <span className='doctor'>{value.headDoctor}</span>
            </span>);
        
        return React.cloneElement(element, { ...element.props }, itemChildren);
    }

    filterChange = (event) => {        
        this.setState({
            data: this.filterData(event.filter),
        });
    }

    filterData(filter) {        
        const v = filter.value.toLowerCase();
        return this.getAllData().filter(w => w.code.toLowerCase().includes(v) ||
            w.name.toLowerCase().includes(v) ||
            w.city.toLowerCase().includes(v) ||
            w.headDoctor.toLowerCase().includes(v));
    }

    getAllData() {
        return !this.props.data ? Dictionaries.centres : this.props.data;
    }

    render() {
        return (
            <DropDownList
                className='centreDropDownList'
                data={this.state.data}
                filterable={true}
                onFilterChange={this.filterChange}
                textField={'code'}
                dataItemKey={'code'}
                value={this.props.value}
                onChange={(e) => this.props.onChange(e.target.value)}
                itemRender={this.itemRender}
                valueRender={this.valueRender}
                popupSettings={{ width: '600px', className: 'centreDropDownListPopup' }}                
            />
        );
    }
}


