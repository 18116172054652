import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';  

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

unregister();
//registerServiceWorker();

// Na server service worker zp�soboval podivn� cacheov�n� v�sledk� vol�n� zobrazen� logu, p��lohy emailu a souhlasu pacienta. 
// Vracela se m�sto obsahu hlavn� str�nka.