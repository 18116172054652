import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { NumericTextBox } from '@progress/kendo-react-inputs';

import WSClient, { erOK, erWrongSMSCode } from '../WSClient.js';
import constants from '../Constants.js';

import './Login.css';
import './LoginSecondFactor.css';

class LoginSecondFactorRegister extends Component {

    constructor(props) {
        super(props);

        this.edit = null;

        // Stav
        this.state = {
            smsSent: false,
        };
    }

    // Registrace certifikátu
    registerCertificate(code, callback) {
        WSClient.registerCertificate(code, callback);
    }

    // Odeslání SMS s kódem
    sendSMS = () => {
        WSClient.sendSMSCode(() => { this.setState({ smsSent: true }, () => this.edit.focus()) });
    }
    
    //
    onRegister = () => {
        this.registerCertificate(this.edit.value.toString(), (result) => {
            if (result !== erOK) {
                if (result === erWrongSMSCode)
                    alert("Zadaný kód je chybný.");
            } else {
                const c = WSClient.currentUser;
                c.clientCertificateState = erOK;
                WSClient.currentUser = c;

                this.props.setSecondFactor(constants.sfNone);
            }
        });
    }

    //
    onCancel = () => {
        WSClient.closeAccessToken();
        this.props.setSecondFactor(constants.sfNone);
    }

    //
    onCodeChanged = (code) => {        
        if (code.toString().length === 6)
            this.onRegister();
    }

    // 
    render() {
        return this.props.secondFactor === constants.sfRegister ?
            <Formik
                initialValues={{
                    code: null,                    
                }}
                validationSchema={Yup.object().shape({                    
                })}
                onSubmit={(values, { setSubmitting }) => {                    
                }}
            >
                {props =>
                (
                    <div className="loginTable loginTableSecondFactor">
                        <div>
                            <div>
                                <div className='loginHeader'>
                                    <div>
                                        <div className="title">Přihlásit se do RDP</div>
                                        <div className="subTitle">Vstup pro registrované DS ČR</div>
                                    </div>
                                </div>
                                <div className='loginBody'>
                                    <Form className="loginForm k-form-inline">
                                            <React.Fragment>
                                            <div>Registraci certifikátu dokončete opsáním kódu doručeného na váš telefon.</div>
                                            </React.Fragment>
                                        <button type="button" className="k-button k-primary send" disabled={props.isSubmitting} onClick={this.sendSMS}>Odeslat SMS s ověřovacím kódem</button>
                                        <label className="k-form-field">
                                            <Field className={props.touched.code && props.errors.code ? "k-textbox valInputError" : "k-textbox"}                                                
                                                name="code"                                                                                                                                             
                                            >
                                                {({ field, form, meta }) =>
                                                    <NumericTextBox                                                        
                                                        ref={edit => this.edit = edit}
                                                        placeholder="SMS kód"
                                                        disabled={!this.state.smsSent }
                                                        spinners={false}
                                                        format='######'
                                                        value={props.values["code"]}
                                                        onChange={(e) => { props.setFieldValue("code", e.target.value, true); this.onCodeChanged(e.target.value); }}
                                                        onBlur={(e) => props.setFieldTouched("code")}
                                                    />
                                                }
                                            </Field>
                                            <button type="button" className="k-button k-primary" onClick={this.onCancel}>Storno</button>
                                        </label>
                                        {props.touched.code && props.errors.code && <p className="valError">{props.errors.code}</p>}
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </Formik>
            : "";
    }
}

export default withRouter(LoginSecondFactorRegister);
