import axios from 'axios';
import { formatDate } from '@telerik/kendo-intl';

import constants from './Constants.js';
import Dictionaries from './Dictionaries.js';
import DateTimeRtns from 'utils/DateTimeRtns.js';

// Možné výsledky operací        
// Vrací se vždy, když operace dopadla úspěšně
export const erOK = 0;
// Obecná nerozlišená chyba
export const erError = 1;
//Došlo k pádu služby, interní chyba datové služby
export const erFatalError = 2;


// Neznámé uživatelské jméno nebo chybné heslo
export const erLogonFailureUnknownUsernameOrBadPassword = 100;
// Požadované středisko není pro uživatele dostupné
export const erWrongCentreCode = 101;
// Guid neodpovídá žádnému známému tokenu, nebo je již token neplatný
export const erInvalidAccessTokenGuid = 102;
// Přístup k požadované operaci nad daty nebyl povolen
export const erPermissionDenied = 103;
// Pro přihlášení nebyl použit klientský certfikát
export const erNoClientCertficate = 104;
// Uživatel nemá zaregistrovaný žádný aktivní certifikát
export const erUserHasNoActiveCertficate = 105;
// Klienský certifikát neodpovídá tomu zaregistrovanému
export const erClientCertificateNotMatchRegisteredOne = 106;
// Pokus o modifikaci dat mimo povolené období
export const erNoEditInterval = 107;
// Chybný SMS kód
export const erWrongSMSCode = 108;


// Požadovaný záznam nebyl v databázi nalezen
export const erDBRecordNotFound = 200;

// Při požadavku na změnu hesla nesouhlasí ověření starého hesla
export const erPasswordNotMatch = 300;


// Klient REST rozhraní datové služby nefrisu
class WSClient {

    //
    static static_constructor() {

        // Stavové proměnné        
        WSClient._lastCommunicationResult = {
            succeed: true,
            result: 0,
            errorMessage: ''
        };

        WSClient._userChangeListeners = [];
        WSClient._lastCommunicationResultListeners = [];

        WSClient._activeCalls = [];
        WSClient._activeCallsChangeListeners = [];

        // Základní společná konfigurace komunikace
        axios.defaults.baseURL = WSClient.webServiceBaseURL;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.post['Content-Type'] = 'application/json';
    }

    //#region Properties

    // URL API aplikace
    static get webServiceBaseURL() {
        return window.location.origin + "/api/";
    }

    // Informace o právě přihlášeném uživateli    
    static get currentUser() {
        const v = window.sessionStorage.getItem("currentUser");
        return v ? JSON.parse(v) : {
            userName: '',
            password: '',
            accessTokenGuid: '',
            firstName: '',
            surname: ''
        };
    }

    static set currentUser(value) {
        window.sessionStorage.setItem("currentUser", JSON.stringify(value));
        WSClient._userChangeListeners.forEach(w => w());
    }

    static get currentCentreCode() {
        return WSClient.currentUser.centreCode;
    }

    static get isCurrentUserAdministrator() {
        return WSClient.isCurrentUserInGroup(constants.ugAdministrators) && WSClient.currentUser.clientCertificateState === erOK;
    }

    static get isCurrentUserRDPCentre() {
        return WSClient.isCurrentUserInGroup(constants.ugRDPCentre) && WSClient.currentUser.clientCertificateState === erOK;
    }

    static get isCurrentUserRIPCentre() {
        return WSClient.isCurrentUserInGroup(constants.ugRIPCentre) && WSClient.currentUser.clientCertificateState === erOK;
    }

    static get isCurrentUserParamOfTreatQuality() {
        return WSClient.isCurrentUserInGroup(constants.ugParamOfTreatQuality) && WSClient.currentUser.clientCertificateState === erOK;
    }

    static get isCurrentUserMBAuthor() {
        return WSClient.isCurrentUserInGroup(constants.ugMBAuthor) && WSClient.currentUser.clientCertificateState === erOK;
    }

    static get isCurrentUserInsuranceCompany() {
        return WSClient.isCurrentUserInGroup(constants.ugInsuranceCompany) && WSClient.currentUser.clientCertificateState === erOK;
    }

    static set adminSelectedCentreCode(value) {
        WSClient._adminSelectedCentreCode = value;
    }

    // Zde se drží vybrané středisko přednstavované do výběrových komboboxů při přihlášení administrátora
    static get adminSelectedCentreCode() {
        if (WSClient._adminSelectedCentreCode)
            return WSClient._adminSelectedCentreCode
        else
            return WSClient.currentUser.centreCode;
    }

    // Zaregistrování funkce, která má být volána ve chvíli, kdy dojde ke změně přihlášeného uživatele
    static addUserChangeListener(callback) {
        WSClient._userChangeListeners.push(callback);
    }

    // Odregistrování funkce, která má být volána ve chvíli, kdy dojde ke změně přihlášeného uživatele
    static removeUserChangeListener(callback) {
        var index = WSClient._userChangeListeners.indexOf(callback)
        if (index !== -1)
            WSClient._userChangeListeners.splice(index, 1);
    }

    // Informace o výsledku posledního volání WS    
    static get lastCommunicationResult() {
        return WSClient._lastCommunicationResult;
    }

    static set lastCommunicationResult(value) {
        WSClient._lastCommunicationResult = value;
        WSClient._lastCommunicationResultListeners.forEach(w => w());
    }

    // Zaregistrování funkce, která má být volána ve chvíli, kdy je k dispozici nový výsledke komunikace se serverem
    static addlastCommunicationResultListeners(callback) {
        WSClient._lastCommunicationResultListeners.push(callback);
    }

    // Odregistrování funkce, která má být volána ve chvíli, kdy je k dispozici nový výsledke komunikace se serverem
    static removeLastCommunicationResultListeners(callback) {
        var index = WSClient._lastCommunicationResultListeners.indexOf(callback)
        if (index !== -1)
            WSClient._lastCommunicationResultListeners.splice(index, 1);
    }

    // Informace o právě probíhajících volání na server
    static get activeCalls() {
        return WSClient._activeCalls;
    }

    static addActiveCall(call) {
        WSClient._activeCalls.push(call);
        WSClient._activeCallsChangeListeners.forEach(w => w());
    }

    static removeActiveCall(call) {
        var index = WSClient._activeCalls.indexOf(call)
        if (index !== -1)
            WSClient._activeCalls.splice(index, 1);

        WSClient._activeCallsChangeListeners.forEach(w => w());
    }

    // Zaregistrování funkce, která má být volána ve chvíli, kdy se mění stav právě probíhajících volání na server
    static addActiveCallsListeners(callback) {
        WSClient._activeCallsChangeListeners.push(callback);
    }

    // Odregistrování funkce, která má být volána ve chvíli, kdy se mění stav právě probíhajících volání na server
    static removeActiveCallsListeners(callback) {
        var index = WSClient._activeCallsChangeListeners.indexOf(callback)
        if (index !== -1)
            WSClient._activeCallsChangeListeners.splice(index, 1);
    }


    //#endregion

    //#region Security

    // Zjištění verze aplikace
    static getVersion(callback) {

        const url = '/Security/GetVersion';

        WSClient.addActiveCall(url);

        axios.post(url)
            .then(function (response) {
                const r = response.data;
                WSClient.handleResult(url, r);

                if (r.result === erOK) {
                    callback(r.data);
                }
                else                    
                    callback(null);

                WSClient.removeActiveCall(url);
            })
            .catch(function (error) {
                WSClient.handleError(error);                
                callback(null);
                WSClient.removeActiveCall(url);
            });
    }

    // Přihlášení zaměstnance, který chce se službou pracovat
    static createAccessToken(userName, password, centreCode, callback) {

        const url = '/Security/CreateAccessToken';

        WSClient.addActiveCall(url);

        axios.post(url, { userName, password, centreCode })
            .then(function (response) {
                const r = response.data;
                WSClient.handleResult(url, r);

                if (r.result === erOK) {
                    WSClient.handleAccessTokenCreation(userName, password, r.data, callback);
                }
                else
                    if (callback !== undefined)
                        callback(null);

                WSClient.removeActiveCall(url);
            })
            .catch(function (error) {
                WSClient.handleError(error);

                if (callback !== undefined)
                    callback(null);

                WSClient.removeActiveCall(url);
            });
    }

    // Odhlášení právě přihlášeného uživatele
    static closeAccessToken() {
        const url = '/Security/CloseAccessToken';
        const accessTokenGuid = WSClient.currentUser.accessTokenGuid;

        WSClient.addActiveCall(url);

        axios.post(url, { accessTokenGuid })
            .then(function (response) {
                const r = response.data;

                if (r.result === erOK || r.result === erInvalidAccessTokenGuid)
                    WSClient.currentUser = {
                        userName: '',
                        password: '',
                        accessTokenGuid: '',
                        firstName: '',
                        surname: '',
                        data: null,
                    };
                else
                    WSClient.handleResult(url, r);

                WSClient.removeActiveCall(url);
            })
            .catch(function (error) {
                WSClient.handleError(error);
                WSClient.removeActiveCall(url);
            });
    }

    // Změna střediska pro právě přihlášeného uživatele
    static ChangeAccessTokenCentre(centreCode) {

        const url = '/Security/ChangeAccessTokenCentre';
        const accessTokenGuid = WSClient.currentUser.accessTokenGuid;

        WSClient.addActiveCall(url);

        axios.post(url, { accessTokenGuid, centreCode })
            .then(function (response) {
                const r = response.data;
                WSClient.handleResult(url, r);

                if (r.result === erOK) {
                    let user = WSClient.currentUser;
                    user.centreCode = centreCode;
                    WSClient.currentUser = user;
                }

                WSClient.removeActiveCall(url);
            })
            .catch(function (error) {
                WSClient.handleError(error);
                WSClient.removeActiveCall(url);
            });
    }

    // Načtení informací o klientském certifikátu
    static GetCertificateInfo(callback) {
        WSClient.callMethod('/Security/GetCertificateInfo', callback, false, {});
    }

    // Registrace nového klientského certitikátu
    static registerCertificate(smsCode, callback) {
        WSClient.callMethod('/Security/RegisterCertificate', callback, false, { smsCode });
    }

    // Odeslání SMS s kódem
    static sendSMSCode(callback) {
        WSClient.callMethod('/Security/SendSMSCode', callback, false, {});
    }

    // Ověření uživatele pomocí sms kódu místo certfikátu
    static smsValidation(smsCode, callback) {
        WSClient.callMethod('/Security/SmsValidation', callback, false, { smsCode });
    }

    //#endregion

    //#region Admin

    // Změna hesla uživatele
    static changeUserPassword(userID, oldPassword, newPassword, callback) {
        WSClient.callMethod('/Admin/ChangeUserPassword', callback, false, { userID, oldPassword, newPassword });
    }

    // Načtení jednoho uživatele
    static getUser(userID, callback) {
        WSClient.callMethod('/Admin/GetUser', callback, false, { userID });
    }

    // Načtení seznamu zaměstnanců    
    static getUserCollection(filter, idOnly, callback) {
        WSClient.callMethod('/Admin/GetUserCollection', callback, false, { filter, idOnly });
    }

    // Uložení uživatele
    static saveUser(user, callback) {
        WSClient.callMethod('/Admin/SaveUser', callback, false, { user });
    }

    // Vymazání uživatele
    static deleteUser(userID, callback) {
        WSClient.callMethod('/Admin/DeleteUser', callback, false, { userID });
    }

    // Načtení výsledků analýzy importu dat od VZP
    static getVZPImportResult(year, callback) {
        WSClient.callMethod('/Admin/GetVZPImportResult', callback, false, { year });
    }    

    // Vymazání dat statistiky pro www.nefro.cz
    static deleteRRTDataFile(yearTo, quarterTo, callback) {
        WSClient.callMethod('/Admin/DeleteRRTDataFile', callback, false, { yearTo, quarterTo });
    }

    // Přidání dat statistiky pro www.nefro.cz
    static addRRTDataFile(yearTo, quarterTo, imageId, language, html, callback) {
        WSClient.callMethod('/Admin/AddRRTDataFile', callback, false, { yearTo, quarterTo, imageId, language, html });
    }

    // Vytvoření náhledu dat statistiky pro www.nefro.cz
    static reportsDirectDataExport(year, callback) {
        WSClient.callMethod('/Admin/ReportsDirectDataExport', callback, false, { year });
    }

    // Zveřejnění dat statistiky pro www.nefro.cz
    static reportsDirectDataPublish(year, callback) {
        WSClient.callMethod('/Admin/ReportsDirectDataPublish', callback, false, { year });
    }

    // Načtení seznamu povolení editací    
    static getEditPermissionCollection(filter, callback) {
        WSClient.callMethod('/Admin/GetEditPermissionCollection', callback, false, { filter });
    }

    // Ověření povolení editace
    static checkEditPermission(dataType, centreCode, year, quarter, callback) {
        WSClient.callMethod('/Admin/CheckEditPermission', callback, false, { dataType, centreCode, year, quarter });
    }

    // Uložení povolení editace
    static saveEditPermission(editPermission, callback) {
        WSClient.callMethod('/Admin/SaveEditPermission', callback, false, { editPermission });
    }

    // Vymazání povolení editace
    static deleteEditPermission(editPermissionID, callback) {
        WSClient.callMethod('/Admin/DeleteEditPermission', callback, false, { editPermissionID });
    }

    // Vymazání pacienta
    static deletePatient(patientID, callback) {
        WSClient.callMethod('/Admin/DeletePatient', callback, false, { patientID });
    }

    // Stažení zálohy databáze
    static getDatabaseExport(year, quarter, callback) {

        const methodName = "databaseExport";
        const accessTokenGuid = WSClient.currentUser.accessTokenGuid;

        // Pokud nemáme validní guid, volání nemá smysl provádět
        if (!accessTokenGuid)
            return;

        WSClient.addActiveCall(methodName);

        //
        axios.get(`Admin/DatabaseExport?accessTokenGuid=${accessTokenGuid}&year=${year}&quarter=${quarter}`)
            .then(function (response) {

                // Hodnoty
                if (response.status === 200)
                    callback(response.data);
                else
                    callback("");

                // Konec volání                
                WSClient.removeActiveCall(methodName);
            })
            .catch(function (error) {
                callback("");

                // Konec volání                
                WSClient.removeActiveCall(methodName);
            });
    }


    //#endregion

    //#region MessageBoard

    // Načtení seznamu zpráv
    static getMessageBoardCollection(filter, callback) {
        WSClient.callMethod('/MessageBoard/GetMessageBoardCollection', callback, false, { filter });
    }

    // Načtení jedné zprávy
    static getMessageBoard(messageID, callback) {
        WSClient.callMethod('/MessageBoard/GetMessageBoard', callback, false, { messageID });
    }

    // Uložení zprávy
    static saveMessageBoard(message, picture, callback) {
        WSClient.callMethod('/MessageBoard/SaveMessageBoard', callback, false, { message, picture });
    }

    // Označení jedné zprávy jako vymazané
    static deleteMessageBoard(messageID, callback) {
        WSClient.callMethod('/MessageBoard/DeleteMessageBoard', callback, false, { messageID });
    }

    //#endregion

    //#region Dictionary

    // Načtení seznamu středisek    
    static getCentreCollection(filter, callback) {
        WSClient.callMethod('/Dictionary/GetCentreCollection', callback, false, { filter });
    }

    // Načtení seznamu skupin zaměstnanců    
    static getUserGroupCollection(filter, callback) {
        WSClient.callMethod('/Dictionary/GetUserGroupCollection', callback, false, { filter });
    }

    // Načtení jedné skupiny uživatel
    static getUserGroup(userGroupID, callback) {
        WSClient.callMethod('/Dictionary/GetUserGroup', callback, false, { userGroupID });
    }

    // Uložení skupiny uživatel
    static saveUserGroup(userGroup, callback) {
        WSClient.callMethod('/Dictionary/SaveUserGroup', callback, false, { userGroup });
    }

    // Vymazání jedné skupiny uživatel
    static deleteUserGroup(userGroupID, callback) {
        WSClient.callMethod('/Dictionary/DeleteUserGroup', callback, false, { userGroupID });
    }

    // Načtení seznamu diagnóz
    static getDiagnosisCollection(filter, callback) {
        WSClient.callMethod('/Dictionary/GetDiagnosisCollection', callback, false, { filter });
    }

    // Načtení seznamu diagnóz EDTA
    static getEDTADiagnosisCollection(filter, callback) {
        WSClient.callMethod('/Dictionary/GetEDTADiagnosisCollection', callback, false, { filter });
    }

    // Načtení ATCHS léků
    static getATCHSCollection(filter, callback) {
        WSClient.callMethod('/Dictionary/GetATCHSCollection', callback, false, { filter });
    }

    //#endregion

    //#region Mail

    // Načtení seznamu emailů
    static getMailCollection(filter, callback) {
        WSClient.callMethod('/Mail/GetMailCollection', callback, false, { filter });
    }

    // Načtení jednoho emailů
    static getMail(mailID, callback) {
        WSClient.callMethod('/Mail/GetMail', callback, false, { mailID });
    }

    // Vymazání jednoho emailů
    static deleteMail(mailID, callback) {
        WSClient.callMethod('/Mail/DeleteMail', callback, false, { mailID });
    }

    // Vytvoření jednoho emailů
    static newMail(mail, attachments, attachmentsToCopy, callback) {
        WSClient.callMethod('/Mail/NewMail', callback, false, { mail, attachments, attachmentsToCopy });
    }

    //#endregion

    //#region Statistiky

    // Parametry kvality léčby
    static treatQualityParamQuarter(centreCode, year, quarter, callback, hideCalling) {
        WSClient.callMethod('/Statistic/TreatQualityParamQuarter', callback, hideCalling, { centreCode, year, quarter });
    }

    // Parametry kvality léčby
    static treatQualityParamQuarterByCentre(year, quarter, onlyHD, callback, hideCalling) {
        WSClient.callMethod('/Statistic/TreatQualityParamQuarterByCentre', callback, hideCalling, { year, quarter, onlyHD });
    }

    // Parametry kvality léčby
    static treatQualityParamQuarterCollection(centreCode, callback, hideCalling) {
        WSClient.callMethod('/Statistic/TreatQualityParamQuarterCollection', callback, hideCalling, { centreCode });
    }

    // Parametry kvality léčby
    static treatQualityParamYear(centreCode, year, consentOnly, callback) {
        WSClient.callMethod('/Statistic/TreatQualityParamYear', callback, false, { centreCode, year, consentOnly });
    }

    // Parametry kvality léčby - data pro výstup do excelu
    static treatQualityParamExcel(centreCode, year, quarter, callback) {
        WSClient.callMethod('/Statistic/TreatQualityParamExcel', callback, false, { centreCode, year, quarter });
    }

    // Parametry kvality léčby - data pro výstup do excelu admin
    static treatQualityParamExcelAdmin(year, callback) {
        WSClient.callMethod('/Statistic/TreatQualityParamExcelAdmin', callback, false, { year });
    }

    // Parametry kvality léčby - data pro výstup do excelu admin VZP
    static treatQualityParamExcelAdminVZP(year, callback) {
        WSClient.callMethod('/Statistic/TreatQualityParamExcelAdminVZP', callback, false, { year });
    }

    // Graf pro statistiku aktuální národní data
    static actualNationalDataReport(chartType, year, callback) {
        WSClient.callMethod('/Statistic/ActualNationalDataReport', callback, false, { chartType, year });
    }

    // Graf pro statistiku aktuální národní data Kaplan Meier
    static actualNationalDataReportKaplanMeierova(rrt, quarterFrom, quarterTo, callback) {
        WSClient.callMethod('/Statistic/ActualNationalDataReportKaplanMeierova', callback, false, { rrt, quarterFrom, quarterTo });
    }

    // Graf pro statistiku porovnání data Kaplan Meier
    static centreReportKaplanMeierova(centreCode, rrt, quarterFrom, quarterTo, callback) {
        WSClient.callMethod('/Statistic/CentreReportKaplanMeierova', callback, false, { centreCode, rrt, quarterFrom, quarterTo });
    }

    // Graf pro statistiku porovnání data hodnocení léčby
    static centreReportTreatmentEval(centreCode, year, callback) {
        WSClient.callMethod('/Statistic/CentreReportTreatmentEval', callback, false, { centreCode, year });
    }

    // Graf pro statistiku porovnání data histogramů průměrných veličin
    static centreReportAverageValueHistogram(centreCode, callback) {
        WSClient.callMethod('/Statistic/CentreReportAverageValueHistogram', callback, false, { centreCode });
    }

    // Výstup ze sumáře 2021
    static summary2021Report(callback) {
        WSClient.callMethod('/Statistic/Summary2021Report', callback, false, { });
    }


    //#endregion

    //#region Data

    // Načtení seznamu pacientů pro zobrazení seznamu pacientů
    static getPatientDataSumCollection(filter, callback) {
        WSClient.callMethod('/Data/GetPatientDataSumCollection', callback, false, { filter });
    }

    // Načtení základních dat jednoho pacienta
    static getPatientDataBase(patientID, year, quarter, callback) {
        WSClient.callMethod('/Data/GetPatientDataBase', callback, false, { patientID, year, quarter });
    }

    // Uložení základních dat jednoho pacienta
    static savePatientDataBase(patientDataBase, courses, wlCourses, vascularAccesses, callback) {
        WSClient.callMethod('/Data/SavePatientDataBase', callback, false, { patientDataBase, courses, wlCourses, vascularAccesses });
    }

    // Načtení seznamu průběhu léčby pacienta
    static getPatientCourseCollection(filter, callback) {
        WSClient.callMethod('/Data/GetPatientCourseCollection', callback, false, { filter });
    }

    // Načtení seznamu cévních přístupů
    static getPatientVascularAccessCollection(filter, callback) {
        WSClient.callMethod('/Data/GetPatientVascularAccessCollection', callback, false, { filter });
    }

    // Načtení průběhu zařazení pacienta do WL
    static getPatientWLCourseCollection(filter, callback) {
        WSClient.callMethod('/Data/GetPatientWLCourseCollection', callback, false, { filter });
    }

    // Načtení seznamu všech dat jednoho pacienta
    static getPatientDataCollection(patientID, callback) {
        WSClient.callMethod('/Data/GetPatientDataCollection', callback, false, { patientID });
    }

    // Změna registru pacienta
    static changePatientStatus(patientID, callback) {
        WSClient.callMethod('/Data/ChangePatientStatus', callback, false, { patientID });
    }

    // Uvolnění pacienta ze střediska
    static freePatientFromCentre(patientID, patientPIN, callback) {
        WSClient.callMethod('/Data/FreePatientFromCentre', callback, false, { patientID, patientPIN });
    }

    // Vrací seznam volných pacientů
    static getFreePatientCollection(filter, callback) {
        WSClient.callMethod('/Data/GetFreePatientCollection', callback, false, { filter });
    }

    // Přiřazení volného pacienta středisku
    static addFreePatientToCentre(patientID, centre, callback) {
        WSClient.callMethod('/Data/AddFreePatientToCentre', callback, false, { patientID, centre });
    }

    // Ověření možnosti provedení editace rodného čísla pacienta
    static checkPINEdit(patientID, newPIN, callback) {
        return WSClient.callMethod('/Data/CheckPINEdit', callback, false, { patientID, newPIN });
    }

    // Ověření korektnosti sekvence průběhu léčby
    static checkCourseEventOrder(courses, callback) {
        return WSClient.callMethod('/Data/CheckCourseEventOrder', callback, false, { courses });
    }    

    // Načtení registračních dat jednoho pacienta
    static getPatientRDPRegistrationData(patientID, callback) {
        WSClient.callMethod('/Data/GetPatientRDPRegistrationData', callback, false, { patientID });
    }

    // Uložení registračních dat jednoho pacienta
    static savePatientRDPRegistrationData(patientData, callback) {
        WSClient.callMethod('/Data/SavePatientRDPRegistrationData', callback, false, { patientData });
    }

    // Načtení registračních dat jednoho pacienta
    static getPatientPORRegistrationData(patientID, callback) {
        WSClient.callMethod('/Data/GetPatientPORRegistrationData', callback, false, { patientID });
    }

    // Uložení registračních dat jednoho pacienta
    static savePatientPORRegistrationData(patientData, callback) {
        WSClient.callMethod('/Data/SavePatientPORRegistrationData', callback, false, { patientData });
    }

    // Načtení periodických dat jednoho pacienta
    static getPatientRDPPeriodData(patientID, year, quarter, callback) {
        WSClient.callMethod('/Data/GetPatientRDPPeriodData', callback, false, { patientID, year, quarter });
    }

    // Načtení periodických dat jednoho pacienta
    static getPatientPORPeriodData(patientID, year, quarter, callback) {
        WSClient.callMethod('/Data/GetPatientPORPeriodData', callback, false, { patientID, year, quarter });
    }

    // Uložení periodických dat jednoho pacienta
    static savePatientRDPPeriodData(patientData, callback) {
        WSClient.callMethod('/Data/SavePatientRDPPeriodData', callback, false, { patientData });
    }

    // Uložení periodických dat jednoho pacienta
    static savePatientPORPeriodData(patientData, callback) {
        WSClient.callMethod('/Data/SavePatientPORPeriodData', callback, false, { patientData });
    }

    // Vymazání periodických dat jednoho pacienta
    static deletePatientRDPPeriodData(id, callback) {
        WSClient.callMethod('/Data/DeletePatientRDPPeriodData', callback, false, { id });
    }

    // Vymazání periodických dat jednoho pacienta
    static deletePatientPORPeriodData(id, callback) {
        WSClient.callMethod('/Data/DeletePatientPORPeriodData', callback, false, { id });
    }

    // Vymazání souhlasu
    static deleteConsent(type, patientID, callback) {
        WSClient.callMethod('/Data/DeleteConsent', callback, false, { type, patientID });
    }

    // Načtení informací o načtených datech střediska
    static getCentreSummaryCollection(centre, callback, hideCalling) {
        WSClient.callMethod('/Data/GetCentreSummaryCollection', callback, hideCalling, { centre });
    }

    // Periodická data o středisku
    static getCentreData(centre, year, callback) {
        WSClient.callMethod('/Data/GetCentreData', callback, false, { centre, year });
    }

    // Uložení data o středisku
    static saveCentreData(centreData, callback) {
        WSClient.callMethod('/Data/SaveCentreData', callback, false, { centreData });
    }

    // Periodická data o středisku načtená ze zázanmů pacientů
    static getCentreDataFromRDP(centre, year, callback) {
        WSClient.callMethod('/Data/GetCentreDataFromRDP', callback, false, { centre, year });
    }

    // Vyhledání pacienta přes všechna střediska
    static searchPatient(pinOrNameOrID, callback) {
        WSClient.callMethod('/Data/SearchPatient', callback, false, { pinOrNameOrID });
    }

    // Editační intervaly jednotlivých kvartálů
    static getEditInterval(callback) {
        WSClient.callMethod('/Data/GetEditInterval', callback, false, {});
    }

    // Seznam pacientů pro příčiny úmrtí 2022
    static getDeathCause2022PatientList(filter, callback) {
        WSClient.callMethod('/Data/GetGetDeathCause2022PatientList', callback, false, { filter });
    }

    // Seznam pacientů pro sumář 
    static getSummaryPatientList(filter, callback) {        
        WSClient.callMethod('/Data/GetSummaryPatientList', callback, false, { filter });
    }

    // Seznam pacientů pro sumář 2021
    static getSummary2021PatientList(filter, callback) {
        WSClient.callMethod('/Data/GetSummary2021PatientList', callback, false, { filter });
    }

    // Seznam pacientů pro sumář 2021
    static setSummary2021PatientData(data, callback) {        
        WSClient.callMethod('/Data/SetSummary2021PatientData', callback, true, { ...data });
    }

    // Seznam pacientů pro sumář 2021 - úmrtí s covidem
    static getSummary2021ExitusPatientList(filter, callback) {
        WSClient.callMethod('/Data/GetSummary2021ExitusPatientList', callback, false, { filter });
    }

    // Uložení úmrtí v souvislosti s covidem
    static setSummary2021PatientCOVIDExitusData(data, callback) {
        WSClient.callMethod('/Data/SetSummary2021PatientCOVIDExitusData', callback, true, { ...data });
    }

    // Uložení průběhu léčby
    static savePatientCourse(patientID, courses, callback) {
        WSClient.callMethod('/Data/SavePatientCourse', callback, true, { patientID, courses });
    }

    // Načtení dat pro pojišťovnu
    static getInsCompPatientCollection(filter, callback) {
        WSClient.callMethod('/Data/GetInsCompPatientCollection', callback, false, { filter });
    }

    // Načtení zipu se souhlasy pacientů pro pojišťovnu
    static getConsentCollection(insuranceCompany, year, callback) {

        const methodName = "getConsentCollection";
        const accessTokenGuid = WSClient.currentUser.accessTokenGuid;

        // Pokud nemáme validní guid, volání nemá smysl provádět
        if (!accessTokenGuid)
            return;

        WSClient.addActiveCall(methodName);

        // Inicializace zobzení progresu
        const timer = window.setInterval(WSClient.getConsentCollectionProgress, 1000);

        // Samotné volání
        axios.get(`Data/GetConsentCollection?accessTokenGuid=${accessTokenGuid}&ic=${insuranceCompany}&year=${year}`, {
            responseType: "blob", onDownloadProgress: (progressEvent) => {
                window.clearInterval(timer);
                WSClient.setLoadingDlgText(`Probíhá stahování souboru ${Math.round(progressEvent.loaded / (1024 * 1024))} MB / ${Math.round(progressEvent.total / (1024 * 1024))} MB`);                
            }
        })
            .then(function (response) {

                WSClient.setLoadingDlgText(null);

                // Hodnoty
                if (response.status === 200)
                    callback(response.data);
                else
                    callback("");

                // Konec volání                
                WSClient.removeActiveCall(methodName);
            })            
            .catch(function (error) {
                callback("");
                WSClient.setLoadingDlgText(null);

                // Konec volání                
                WSClient.removeActiveCall(methodName);
            });
    }

    // Zbrazení progresu stahování souboru pro pojišťovny
    static getConsentCollectionProgress() {

        const accessTokenGuid = WSClient.currentUser.accessTokenGuid;

        axios.get(`Data/GetConsentCollectionProgress?accessTokenGuid=${accessTokenGuid}`)
            .then(function (response) {

                if (response.status === 200)
                    WSClient.setLoadingDlgText(response.data);
            });                    
    }

    //#endregion

    //#region Log

    // Načtení seznamu importních souborů z daného dne
    static getLogImportFiles(date, callback) {
        WSClient.callMethod('/Admin/GetLogImportFilesCollection', callback, false, { date });
    }
    

    // Načtení obsahu logu z jednoho dne
    static getLogFile(date, callback) {

        const methodName = "getLogFile";
        const accessTokenGuid = WSClient.currentUser.accessTokenGuid;

        // Pokud nemáme validní guid, volání nemá smysl provádět
        if (!accessTokenGuid)
            return;

        WSClient.addActiveCall(methodName);

        // Date chceme vždy přenášet jako lokální čas ne jako UTC.
        // Je problém docílit toho, aby server i client stejně převáděli UTC na lokální čas (letní čas).        
        const p = this.convertDates(date);

        //
        axios.get(`Admin/GetLogFile?accessTokenGuid=${accessTokenGuid }&date=${formatDate(p, "yyyy-MM-dd", "cs")}`)
            .then(function (response) {

                // Hodnoty
                if (response.status === 200)
                    callback(response.data);
                else
                    callback("");                

                // Konec volání                
                WSClient.removeActiveCall(methodName);
            })
            .catch(function (error) {               
                callback("");

                // Konec volání                
                WSClient.removeActiveCall(methodName);
            });
    }


    //#endregion

    //#region Různé pomocné funkce

    // Zpracování návratové hodnoty volání WS
    static handleResult(url, response) {
        WSClient.lastCommunicationResult = {
            succeed: response.result === erOK,
            result: response.result,
            errorMessage: response.errorMessage === null ? WSClient.resultToError(response.result) : response.errorMessage
        };

        if (!WSClient.lastCommunicationResult.succeed) {
            console.log(`Last communication result: ${WSClient.lastCommunicationResult.result} - "${WSClient.lastCommunicationResult.errorMessage}, Url: ${url}"`);
        }
    }

    // Zpracování chyby při volání WS
    static handleError(error) {
        WSClient.lastCommunicationResult = {
            succeed: false,
            result: erError,
            errorMessage: error.message
        };

        if (!WSClient.lastCommunicationResult.succeed)
            console.log(`Last communication exception: ${WSClient.lastCommunicationResult.result} - "${WSClient.lastCommunicationResult.errorMessage}"`);
    }

    // Převedení chybové kódu na textový popis chyby
    static resultToError(result) {
        switch (result) {
            case erOK: return '';
            case erError:
            case erFatalError: return 'Chyba';

            case erLogonFailureUnknownUsernameOrBadPassword: return 'Neznámé jméno nebo chybné heslo';
            case erWrongCentreCode: return 'Požadované středisko není pro uživatele dostupné';
            case erInvalidAccessTokenGuid: return 'Guid neodpovídá žádnému známému tokenu, nebo je již token neplatný';
            case erPermissionDenied: return 'Přístup k požadované operaci nebyl povolen';

            case erNoClientCertficate: return 'Pro přihlášení nebyl použit klientský certfikát';
            case erUserHasNoActiveCertficate: return 'Uživatel nemá zaregistrovaný aktivní certifikát';
            case erClientCertificateNotMatchRegisteredOne: return 'Klienský certifikát neodpovídá tomu zaregistrovanému';

            case erDBRecordNotFound: return 'Požadovaný záznam nebyl v databázi nalezen';

            case erPasswordNotMatch: return 'Nesouhlasí ověření starého hesla';

            default: return 'Neznámá chyba';
        }
    }

    // Zjistí, zda volání neselhalo z důvodu neplatnosti tokenu.
    // Pokud ano, pokusí se znovu přihlásit uživatele a opakovat volání.
    static erInvalidAccessTokenGuidRepeatCall(result, repeatCallAfterLogin, call) {

        // Pokud již není token platný, pokusíme se znovu přihlásit uživatele a opakovat volání
        if (result === erInvalidAccessTokenGuid &&
            WSClient.currentUser.userName !== '' &&
            repeatCallAfterLogin !== true) {

            WSClient.createAccessToken(
                WSClient.currentUser.userName,
                WSClient.currentUser.password,
                WSClient.currentUser.centreCode,
                call);

            // Provede se opakované volání. Původní funkce může končit.
            return false;
        } else
            // Token je platný, nebo již jsme v opakovaném volání. Původní funkce může klidně dála pokračovat.
            return true;
    }

    // Všechny datumy jako string, kde bude čas vyjádřen lokálně a ne jako UTC
    static convertDates(p) {

        // Undefined and null
        if (p === undefined || p === null)
            return p;

        // U pole voláme pro každou položku opět rekursivně
        if (p.constructor === Array)
            return p.map(w => this.convertDates(w));

        // Datum je to, co nás hlavně zajímá
        if (p.constructor === Date)
            return DateTimeRtns.toLocalDateTime(p);

        // U objektů jdeme přes všechny properties
        if (p.constructor === Object) {
            let r = {};
            Object.keys(p).forEach(key => r[key] = this.convertDates(p[key]));
            return r;
        }

        // Jenoduché typy se prostě vrátí nezměněné        
        return p;
    }

    // Zavolání metody WS s opakovaným přihlášením a obsluhou chyb 
    static callMethod(methodName, callback, hideCalling, parameters) {
        WSClient.callMethodH(methodName, callback, hideCalling, false, parameters);
    }

    // Zavolání metody WS s opakovaným přihlášením a obsluhou chyb 
    static callMethodH(methodName, callback, hideCalling, repeatCallAfterLogin, parameters) {

        const accessTokenGuid = WSClient.currentUser.accessTokenGuid;

        // Pokud nemáme validní guid, volání nemá smysl provádět
        if (!accessTokenGuid)
            return;

        if (!hideCalling)
            WSClient.addActiveCall(methodName);

        // Date chceme vždy přenášet jako lokální čas ne jako UTC.
        // Je problém docílit toho, aby server i client stejně převáděli UTC na lokální čas (letní čas).        
        const p = this.convertDates(parameters);

        //
        axios.post(methodName, { accessTokenGuid, ...p })
            .then(function (response) {
                const r = response.data;

                // Pokud již není token platný, pokusíme se znovu přihlásit uživatele a opakovat volání
                //if (WSClient.erInvalidAccessTokenGuidRepeatCall(r.result, repeatCallAfterLogin,
                //    () => { WSClient.callMethodH(methodName, callback, hideCalling, true, p); })) {
                //    WSClient.handleResult(methodName, r);
                //    callback(r.result, r.data);
                //}

                // Pokud již není token platný, musí se uživatel znovu přihlásit
                if (r.result === erInvalidAccessTokenGuid) {                    
                    WSClient.currentUser = {
                        userName: '',
                        password: '',
                        accessTokenGuid: '',
                        firstName: '',
                        surname: '',
                        data: null,
                    };
                } else {
                    WSClient.handleResult(methodName, r);
                    callback(r.result, r.data);
                }

                // Konec volání
                if (!hideCalling)
                    WSClient.removeActiveCall(methodName);
            })
            .catch(function (error) {                
                WSClient.handleError(error);
                callback(erFatalError);

                if (!hideCalling)
                    WSClient.removeActiveCall(methodName);
            });
    }

    // Zavolání metody WS s načtením číselníku
    // Volá se pouze ze třídy Dictionaries
    static async loadDictionary(accessTokenGuid, methodName, sortBy) {

        const filter = { sortBy: sortBy, sortDirection: 'asc' };
        const response = await axios.post(methodName, { accessTokenGuid, filter });
        const r = response.data;
        WSClient.handleResult(methodName, r);
        return r.result === erOK ? r.data : undefined;
    }

    static handleAccessTokenCreation(userName, password, data, callback) {
        // Pokud došlo k úspěšnému přihlášení uživatele, nejprve načteme číselníky a teprve potom, necháme pokračovat další kód
        Promise.all(Dictionaries.load(data.accessToken.guid)).then(w => {            
            WSClient.currentUser = {
                userName: userName,
                password: /*password*/'',  // heslo se nezapamatovává
                accessTokenGuid: data.accessToken.guid,
                data: data.accessToken.user,
                centreCode: data.accessToken.centreCode,
                clientCertificateState: data.clientCertificateState,
                clientCertificateNotAfter: data.clientCertificateNotAfter,
                clientCertificateName: data.clientCertificateName,
                registeredCertificateName: data.registeredCertificateName,
            };

            if (callback !== undefined)
                callback(data.accessToken.guid);
        });        
    }

    static isCurrentUserInGroup(group) {
        return WSClient.currentUser.data && WSClient.currentUser.data.groups.includes(group);
    }

    static prepareFilters = (columns, filter) => {
        let result = {};

        if (filter !== null)
            columns.forEach(ww => {
                const v = filter.filters.find(w => w.field === ww);
                if (v !== undefined)                    
                    result[ww] = v.value.value !== undefined ? v.value.value : v.value;
            });

        return result;
    }

    //#endregion
}

export default WSClient;


